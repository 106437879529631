.filter-container {
  margin: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  .filter-control {
    display: flex;
    align-items: center;
    margin: 0.5rem;

    label {
      margin: 0px 0.2rem 0px 0.5rem 
    }
    .settings {
      cursor: pointer;
      background-color: dimgray;
      svg {
        animation-name: spin;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-play-state: paused
      }
    }
  
    .settings:hover {
      svg {
        animation-play-state: running
      }
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .filter-container {
    flex-direction: column;
  }
}

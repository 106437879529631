.filter-setting-container {
  background-color: mintcream;
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.filter-setting-modal {
  opacity: 1;
  width: 50vw;
  height: 30vh;
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 25%;
  background-color: #282c34;
  border-radius: 5px;
  box-shadow: 5px 5px 5px grey;

  .filter-setting-modal-close {
    text-align: right;
    padding: 8px;
    svg {
      cursor: pointer;
    }
  }

  .filter-setting-modal-main {
    .filter-setting-modal-row {
      display: flex;
      justify-content: space-between;
      margin: 10px 25px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .filter-setting-modal {
    width: 100vw;
    left: 0%;
    .filter-setting-modal-main {
      .filter-setting-modal-row {
        display: block;
      }
    }
  }
}
